import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useModalStyle from "@hooks/admin/useModalStyle";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import {
  Button as RunwayButton,
  color,
  opacity,
  Title,
} from "@runwayhealth/runway-components-react";
import { Order, ShippingMethods } from "@store/../@types/case";
import { RootState } from "@store/../@types/state";
import {
  cancelOrder,
  getOrders,
  resubmitOrder,
  updateOrderShippingMethod,
} from "@store/admin/adminAction";
import { caseObject } from "@store/cases/casesSlice";
import { toastError } from "@utils/utils";

import { getInvoiceIdLink, getMdiLink } from "./CaseSearch";
import OrderManager from "./components/OrderManager";
import StaleOrders from "./StaleOrders";

export const getTrackingLink = (trackingNumber: string, carrier: string) =>
  trackingNumber && trackingNumber !== "" ? (
    <a
      href={
        carrier === "ups"
          ? `https://www.ups.com/track?loc=en_US&requester=QUIC&tracknum=${trackingNumber}/trackdetails`
          : `https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum=${trackingNumber}`
      }
      target="_blank"
      rel="noopener noreferrer"
    >
      {trackingNumber}
    </a>
  ) : (
    <span>N/A</span>
  );

const OrderSearch = () => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { useStyles } = useModalStyle();
  const [page, setPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const orders = useSelector<RootState, Order[]>((state) => state.admin.orders);
  const orderCancelationError = useSelector<RootState, boolean>(
    (state) => state.admin.cancelationError
  );
  const orderResubmitError = useSelector<RootState, boolean>((state) => state.admin.resubmitError);
  const [orderId, setOrderId] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [status, setStatus] = useState("");

  const [selectedOrder, setSelectedOrder] = useState<Order>(caseObject.data.CurexaOrder);

  useEffect(() => {
    setScreenWidth(window.innerWidth);
  }, [window.innerWidth]);

  const classes = useStyles(screenWidth);

  const reloadOrder = () => {
    dispatch(getOrders({ orderId, from, to, status }));
  };
  const cancelAlert = (orderId: number, referenceId: string) => {
    if (confirm(`Are you sure you want to cancel order ${referenceId}?`)) {
      dispatch(cancelOrder({ orderId }));
    }
  };
  const resubmitAlert = (orderId: number, referenceId: string) => {
    if (
      confirm(
        `Are you sure you want to re-submit order ${referenceId}? This order has an error, resubmitting will place the RX in the order again.`
      )
    ) {
      dispatch(resubmitOrder({ referenceId }));
    }
  };
  const shippingMethodAlert = (
    orderId: number,
    referenceId: string,
    chosenShippingMethod: ShippingMethods
  ) => {
    if (
      confirm(
        `Are you sure you want to set ${chosenShippingMethod} shipping method to OID ${referenceId}?`
      )
    ) {
      dispatch(
        updateOrderShippingMethod({
          orderId,
          referenceId,
          shippingMethod: chosenShippingMethod,
        })
      );
    }
  };

  const nextPage = () => {
    let offsetAccum = offset + 30;
    setOffset(offsetAccum);

    let pageCounter = page + 1;
    setPage(pageCounter);

    dispatch(getOrders({ orderId, from, to, status, offset: offsetAccum }));
  };

  const previousPage = () => {
    if (page > 1) {
      let offsetAccum = offset - 30;
      setOffset(offsetAccum);

      let pageCounter = page - 1;
      setPage(pageCounter);

      dispatch(getOrders({ orderId, from, to, status, offset: offsetAccum }));
    }
  };

  useEffect(() => {
    if (orderCancelationError) {
      toastError("Error", "Could not cancel order");
    }
  }, [orderCancelationError]);

  useEffect(() => {
    if (orderResubmitError) {
      toastError("Error", "Could not re-submit order");
    }
  }, [orderResubmitError]);

  return (
    <section>
      <h2>Order Search 📦</h2>
      <div className="noMargin">
        <div>
          <TextField
            label="Order or Case ID"
            value={orderId}
            onChange={(evt) => setOrderId(evt.target.value)}
          />
          <select
            className="nonMaterialAlignment"
            value={status}
            onChange={(event) => {
              setStatus(event.target.value);
            }}
          >
            <option value="">All</option>
            <option value="new">New</option>
            <option value="error">Error</option>
            <option value="in_progress">In Progress</option>
            <option value="out_for_delivery">Out for Delivery</option>
            <option value="completed">Completed</option>
            <option value="cancelled">Cancelled</option>
          </select>
          <TextField
            label="from"
            type="date"
            value={from}
            onChange={(evt) => setFrom(evt.target.value)}
          />
          <TextField
            label="to"
            type="date"
            value={to}
            onChange={(evt) => setTo(evt.target.value)}
          />
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={reloadOrder}
            className="nonMaterialAlignment"
          >
            Search 🔍
          </Button>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Case ID</th>
            <th>Order Created On</th>
            <th>Departure Date</th>
            <th>Payment Status</th>
            <th>Order Status</th>
            <th>Shipping Method</th>
            <th>Tracking Number</th>
            <th>Replacement</th>
            <th>Updated At</th>
            <th>Cancel</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => {
            return (
              !order.externalStatus && (
                <tr key={`order-${order.id}`}>
                  <td>
                    <div>
                      {order.order_id}
                      <br></br>
                      {order.invoiceId && (
                        <RunwayButton
                          size="sm"
                          onClick={() => {
                            setModal(true);
                            setSelectedOrder(order);
                          }}
                          variant="outline"
                        >
                          🔁 Replace Order
                        </RunwayButton>
                      )}
                    </div>
                  </td>
                  <td>{getMdiLink(order.caseId)}</td>
                  <td>{order.createdAt}</td>
                  <td>{order.departureDate}</td>
                  <td>{getInvoiceIdLink(order.invoiceId || "❌")}</td>
                  <td>
                    {order.status ? (
                      order.status === "error" ? (
                        <div>
                          <p>Error</p>
                          <RunwayButton
                            size="sm"
                            onClick={() => {
                              resubmitAlert(order.id, order.order_id);
                            }}
                            variant="outline"
                          >
                            ⚠️ Re-submit
                          </RunwayButton>
                        </div>
                      ) : (
                        order.status
                      )
                    ) : (
                      "N/A"
                    )}
                  </td>
                  <td>
                    <FormControl fullWidth>
                      <Select
                        value={order.shipping_method || ShippingMethods.STANDARD}
                        label="Shipping Method"
                        onChange={(evt: React.ChangeEvent<{ value: unknown }>) => {
                          const chosenShippingMethod: ShippingMethods = evt.target
                            .value as ShippingMethods;
                          shippingMethodAlert(order.id, order.order_id, chosenShippingMethod);
                        }}
                      >
                        {Object.keys(ShippingMethods).map((shippingMethod: string) => {
                          const key: keyof typeof ShippingMethods =
                            shippingMethod as keyof typeof ShippingMethods;
                          return (
                            <MenuItem value={ShippingMethods[key]}>{ShippingMethods[key]}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </td>
                  <td>{getTrackingLink(order.tracking_number, order.carrier)}</td>
                  <td>{JSON.stringify(order?.replacement)}</td>
                  <td>{order.updatedAt}</td>
                  <td>
                    <RunwayButton
                      size="sm"
                      iconName="X"
                      onClick={() => {
                        cancelAlert(order.id, order.order_id);
                      }}
                      isIconOnly
                      variant="outline"
                    />
                  </td>
                </tr>
              )
            );
          })}
        </tbody>
      </table>
      <Modal
        open={modal}
        onClose={() => {
          setModal(false);
        }}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: `${color.neutral.darkest}`, opacity: `${opacity.semiOpaque}` },
        }}
      >
        <Fade in={modal}>
          <div className={classes.paper}>
            <OrderManager order={selectedOrder} closeModal={() => setModal(false)} />
          </div>
        </Fade>
      </Modal>
      <div className="pagination-wrapper">
        <RunwayButton iconName="CaretLeft" isIconOnly variant="outline" onClick={previousPage} />
        <Title>{page}</Title>
        <RunwayButton iconName="CaretRight" isIconOnly variant="outline" onClick={nextPage} />
      </div>
      <Accordion>
        <AccordionSummary>Stale Orders 🛑</AccordionSummary>
        <AccordionDetails>
          <StaleOrders />
        </AccordionDetails>
      </Accordion>
    </section>
  );
};

export default OrderSearch;
