import React from "react";
import styled from "styled-components";

import { from, spacing } from "@runwayhealth/runway-components-react";

const ContentInfoBase = styled.div.attrs({ className: "container" })`
  width: 100%;
  height: auto;
  margin-top: ${spacing.xxs};
  text-align: center;

  @media ${from.mobile} {
    margin-top: 5%;
    text-align: left;
  }

  .form-content {
    margin-top: ${spacing.xxs};
    text-align: left;

    .MuiFormControlLabel-root {
      align-items: flex-start;

      .MuiIconButton-root:hover {
        background-color: transparent;
      }
    }

    .MuiCheckbox-root {
      padding: 6px;
      margin-top: -5px;
      background-color: transparent;
    }
  }

  button {
    margin-top: ${spacing.xxs};
    margin-bottom: ${spacing.xxs};

    @media ${from.mobile} {
      margin-top: ${spacing.md};
    }
  }

  h1 + p {
    @media ${from.mobile} {
      margin-bottom: ${spacing.xxxs};
    }
  }
  .login-content {
    text-align: center;
  }
`;

export const ContentInfo = ({ children }: { children: React.ReactNode }) => {
  return (
    <ContentInfoBase>
      <div className="row justify-content-md-center">
        <div className="col-sm-12 col-md-10 col-lg-7 col-xxl-6">{children}</div>
      </div>
    </ContentInfoBase>
  );
};
