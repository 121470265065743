import Stripe from "stripe";

import { Condition } from "./condition";
import { Address, AddressUpdate } from "./user";

export enum ShippingMethods {
  STANDARD = "standard",
  USPS_PRIORITY = "usps_priority",
  USPS_EXPRESS = "usps_priority_express",
  UPS_NEXT_DAY_AIR = "ups_next_day_air",
  UPS_SECOND_DAY_AIR = "ups_second_day_air",
  FEDX_GROUND = "fedex_ground",
  FEDX_STANDARD = "fedex_standard_overnight",
  FEDX_PRIORITY = "fedex_priority_overnight",
}

export enum CaseStatuses {
  CREATED = "created",
  ASSIGNED = "assigned",
  SUPPORT = "support",
  WAITING = "waiting",
  PROCESSING = "processing",
  COMPLETED = "completed",
  CANCELLED = "cancelled",
}

export enum OrderStatuses {
  NEW = "new",
  IN_PROGRESS = "in_progress",
  OUT_FOR_DELIVERY = "out_for_delivery",
  COMPLETED = "completed",
  CANCELLED = "cancelled",
  ERROR = "error",
}

export enum OrderCaseStatuses {
  PROCESSING = "processing",
  CANCELLED = "cancelled",
  SHIPPED = "shipped",
  COMPLETE = "complete",
}

export interface Question {
  question: string;
  answer: string;
  type: boolean | number | string;
  important: boolean;
  display_in_pdf?: boolean;
}

export interface Replacement {
  replacement_reason?: string;
  status: OrderStatuses;
  carrier?: string;
  tracking_number?: string;
}

export interface Order {
  UserId: string;
  carrier: string;
  caseId: string;
  createdAt: string;
  id: number;
  order_id: string;
  status: string;
  externalStatus?: string;
  invoice?: Stripe.Invoice;
  invoiceId?: string;
  tracking_number: string;
  shipping_method: string | null;
  departureDate: string;
  updatedAt: string;
  Case?: Case;
  replacement?: Replacement;
}

export interface Case {
  id?: number;
  caseId: string | null;
  conditions: Condition[];
  case_status: CaseStatuses | null;
  invoiceId: string | null;
  case_questions: Question[];
  shipping_method: ShippingMethods | null;
  shippingAddress?: Address;
  CurexaOrder: Order;
  case_assigment_id?: string;
  clinician?: {
    id: string;
    fullname: string;
    photo: string;
    specialty: string;
  };
  updatedAt: string;
  createdAt?: string;
  departureDate?: string;
  UserId?: string | null;
  upsell?: UpsellConditions;
  paid: boolean;
}

export interface CaseUpdate {
  caseId?: string | null;
  conditions?: Condition[];
  case_status?: CaseStatuses | null;
  case_questions?: Question[];
  shipping_method?: ShippingMethods | null;
  shippingAddress?: AddressUpdate;
  UserId?: string;
}

export interface Attachment {
  name: string;
  mime_type: string;
  url: string;
  url_thumbnail: string;
  file_id: string;
}

export interface CaseNotification {
  event_type: string;
  case_id: string;
  metadata: "";
}

export interface UpsellConditions {
  conditions: Condition[];
  reason?: string;
}
