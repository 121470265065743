import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import useOrderPrescriptions from "@hooks/admin/useOrderPrescriptions";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Button } from "@runwayhealth/runway-components-react";
import { Order } from "@store/../@types/case";
import { replaceOrder } from "@store/admin/adminAction";

type OrderManagerProps = {
  order: Order;
  closeModal: () => void;
};

type Prescription = {
  case_prescription_id: string;
  name: string;
  quantity: number;
  days_supply: number;
};

const OrderManager = ({ order, closeModal }: OrderManagerProps) => {
  const dispatch = useDispatch();
  const [prescriptions, setPrescriptions] = useState([]);
  const { getOrderPrescriptions } = useOrderPrescriptions();
  useEffect(() => {
    // Get Order Prescriptions.
    (async () => {
      const prescriptions = await getOrderPrescriptions(order.id);
      setPrescriptions(prescriptions);
    })();
  }, []);

  const removePrescription = (prescriptionId: string) => {
    setPrescriptions((prevState) =>
      prevState.filter((item: Prescription) => item.case_prescription_id !== prescriptionId)
    );
  };

  const replaceAlert = () => {
    if (
      confirm(
        `Are you sure you want to replace order ${order.order_id}? 
        
        This will:
          - Cancel the current order.
          - Remove the current order from Runway.
          - Generate a new OID.
          - Send the new order to the pharmacy.
        `
      )
    ) {
      dispatch(
        replaceOrder({
          orderId: order.id,
          referenceId: order.order_id,
          invoiceId: order.invoiceId,
          caseId: order.caseId,
          prescriptions,
        })
      );
      closeModal();
    }
  };

  return (
    <>
      <h1>OID: {order.order_id}</h1>
      <h2>Case: {order.caseId}</h2>
      <hr></hr>
      <h3>Shipping Address</h3>
      <hr></hr>
      <p>
        <b>Street: </b>
        {order.Case?.shippingAddress?.street}
      </p>
      <p>
        <b>Secondary:</b> {order.Case?.shippingAddress?.secondary}
      </p>
      <p>
        <b>City: </b>
        {order.Case?.shippingAddress?.city}
      </p>
      <p>
        <b>State: </b>
        {order.Case?.shippingAddress?.state}
      </p>
      <p>
        <b>Postal Code: </b>
        {order.Case?.shippingAddress?.postalCode}
      </p>
      <p>
        <b>Country:</b> {order.Case?.shippingAddress?.country}
      </p>
      <hr></hr>
      <h2>Prescriptions</h2>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <b>ID</b>
            </TableCell>
            <TableCell>
              <b>Name</b>
            </TableCell>
            <TableCell>
              <b>Quantity</b>
            </TableCell>
            <TableCell>
              <b>Supply Days</b>
            </TableCell>
            {prescriptions.length > 1 && (
              <TableCell>
                Action: <b>Remove</b>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {prescriptions.map((prescription: Prescription) => (
            <TableRow>
              <TableCell>{prescription.case_prescription_id}</TableCell>
              <TableCell>{prescription.name}</TableCell>
              <TableCell>{prescription.quantity}</TableCell>
              <TableCell>{prescription.days_supply}</TableCell>
              {prescriptions.length > 1 && (
                <TableCell align="center">
                  <Button
                    size="sm"
                    iconName="X"
                    isIconOnly
                    variant="outline"
                    onClick={() => removePrescription(prescription.case_prescription_id)}
                  ></Button>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <p style={{ fontSize: "13px" }}>
        *Prescription changes will not reflect in this table after the replacement, since
        prescriptions source is the consultation and not the order.
      </p>
      <div style={{ padding: "15px" }}>
        <Button iconName="ArrowRight" onClick={() => replaceAlert()}>
          Replace Order
        </Button>
      </div>
    </>
  );
};

export default OrderManager;
