import { order } from "../../api";

const useOrderPrescriptions = () => {
  const getOrderPrescriptions = async (orderId: number) => {
    const { data: currentPrescriptions } = await order.orderGet(`/${orderId}/prescriptions`);
    return currentPrescriptions;
  };
  return { getOrderPrescriptions };
};

export default useOrderPrescriptions;
