import styled from "styled-components";

import { from, spacing } from "@runwayhealth/runway-components-react";

export const StartConsultationPage = styled.div`
  width: 100%;

  .help-button {
    position: absolute;
    right: ${spacing.xxxs};
    top: ${spacing.xxxs};
    cursor: pointer;
    z-index: 100;

    span {
      display: none;
    }

    @media ${from.mobile} {
      span {
        display: initial;
      }
    }
  }

  .wrapper-help-modal {
    z-index: 115;
    height: fit-content;
  }
`;
