export const SEARCH_USER_BY_EMAIL = "SEARCH_USER_BY_EMAIL";
export const searchUserByEmail = (payload: any) => ({
  type: SEARCH_USER_BY_EMAIL,
  payload,
});
export const SEARCH_USER_BY_ID = "SEARCH_USER_BY_ID";
export const searchUserById = (payload: any) => ({
  type: SEARCH_USER_BY_ID,
  payload,
});
export const GENERATE_PARTNER_CREDENTIALS = "GENERATE_PARTNER_CREDENTIALS";
export const generatePartnerCredentials = (payload: any) => ({
  type: GENERATE_PARTNER_CREDENTIALS,
  payload,
});
export const SEARCH_PARTNERS = "SEARCH_PARTNERS";
export const searchPartners = (payload: any) => ({
  type: SEARCH_PARTNERS,
  payload,
});
export const SEARCH_TRANSACTIONS = "SEARCH_TRANSACTIONS";
export const searchTransactions = (payload: any) => ({
  type: SEARCH_TRANSACTIONS,
  payload,
});
export const GET_CASES = "GET_CASES";
export const getCases = (payload: any) => ({
  type: GET_CASES,
  payload,
});
export const GET_CASES_BY_USER = "GET_CASES_BY_USER";
export const getCasesByUser = (payload: any) => ({
  type: GET_CASES_BY_USER,
  payload,
});
export const GET_ORDERS = "GET_ORDERS";
export const getOrders = (payload: any) => ({
  type: GET_ORDERS,
  payload,
});
export const GET_PROMO_CODE = "GET_PROMO_CODE";
export const getPromoCode = (payload: any) => ({
  type: GET_PROMO_CODE,
  payload,
});
export const GET_REDEMPTIONS = "GET_REDEMPTIONS";
export const getRedemptions = (payload: any) => ({
  type: GET_REDEMPTIONS,
  payload,
});
export const DELETE_PARTNER = "DELETE_PARTNER";
export const deletePartner = (payload: any) => ({
  type: DELETE_PARTNER,
  payload,
});
export const CANCEL_ORDER = "CANCEL_ORDER";
export const cancelOrder = (payload: any) => ({
  type: CANCEL_ORDER,
  payload,
});

export const RESUBMIT_ORDER = "RESUBMIT_ORDER";
export const resubmitOrder = (payload: any) => ({
  type: RESUBMIT_ORDER,
  payload,
});

export const UPDATE_CASE_SHIPPING = "UPDATE_CASE_SHIPPING";
export const updateCaseShipping = (payload: any) => ({
  type: UPDATE_CASE_SHIPPING,
  payload,
});

export const UPDATE_ORDER_SHIPPING_METHOD = "UPDATE_ORDER_SHIPPING_METHOD";
export const updateOrderShippingMethod = (payload: any) => ({
  type: UPDATE_ORDER_SHIPPING_METHOD,
  payload,
});

export const GET_STALE_ORDERS = "GET_STALE_ORDERS";
export const getStaleOrders = () => ({
  type: GET_STALE_ORDERS,
});

export const SYNC_STALE_ORDER = "SYNC_STALE_ORDER";
export const syncStaleOrder = (payload: any) => ({
  type: SYNC_STALE_ORDER,
  payload,
});

export const CREATE_USER_ADDRESS = "CREATE_USER_ADDRESS";
export const createUserAddress = (payload: any) => ({
  type: CREATE_USER_ADDRESS,
  payload,
});

export const SEND_ADMIN_ORDER = "SEND_ADMIN_ORDER";
export const sendAdminOrder = (payload: any) => ({
  type: SEND_ADMIN_ORDER,
  payload,
});

export const REPLACE_ORDER = "REPLACE_ORDER";
export const replaceOrder = (payload: any) => ({
  type: REPLACE_ORDER,
  payload,
});
