import React from "react";

import { Text, Title } from "@runwayhealth/runway-components-react";

export const RunwayRxText = () => {
  return (
    <div style={{ textAlign: "left" }}>
      <Title size="giant">Welcome to your online consultation!</Title>
      <Text>
        Your online, questionnaire-based consultation will gather information about your upcoming
        trip and health history. This information will be reviewed by a US-licensed physician in
        order to finalize your treatment plan.
      </Text>
      <Text>
        If you have any specific questions for your physician, you will have access to directly
        message them via your account after submitting your consultation.
      </Text>
      <Text>Enter your email below to get started!</Text>
    </div>
  );
};

export const RunwayPlusText = () => (
  <>
    <Title size="giant">Runway+</Title>
    <Text>
      To initiate your consultation, please fill out this short form to tell your physician about
      your specific travel plans. Your physician will use this information to provide you with
      travel health support before, during and after your trip.
    </Text>
    <Text>Enter your email below to get started!</Text>
  </>
);

export const ConsentText = () => {
  return (
    <Text as="span" size="sm">
      Keep me in the loop with current travel tips and news from Runway Health
    </Text>
  );
};
