import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AddressForm from "@components/AddressManager/components/AddressForm";
import { Button, TextField } from "@material-ui/core";
import { Case } from "@store/../@types/case";
import { RootState, UserSearchData } from "@store/../@types/state";
import { Address } from "@store/../@types/user";
import { createUserAddress, getCasesByUser, searchUserByEmail } from "@store/admin/adminAction";

import CaseResults from "./CaseResults";

const UserSearch = () => {
  const [email, setEmail] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const [addressFormIsOpen, setAddressFormIsOpen] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector<RootState, UserSearchData>((state) => state.admin.users);
  const cases = useSelector<RootState, Case[]>((state) => state.admin.cases);

  const findUser = () => {
    dispatch(searchUserByEmail({ email }));
  };

  const createAddress = (address: Address) => {
    dispatch(createUserAddress({ ...address, UserId: userData.id }));
    setAddressFormIsOpen(false);
  };

  useEffect(() => {
    if (userData.id) {
      dispatch(getCasesByUser(userData.id));
    }
  }, [userData.email]);

  return (
    <section>
      <h2>Search User by Email 🕵️</h2>
      <div className="noMargin">
        <TextField
          type="email"
          id="email"
          value={email}
          onChange={(evt) => setEmail(evt.target.value)}
          label="Email"
        />
        <Button
          className="nonMaterialAlignment"
          variant="contained"
          color="primary"
          onClick={findUser}
        >
          Search 🔍
        </Button>
      </div>
      {userData && (
        <div className="noMargin">
          <div
            style={{ cursor: "pointer", marginLeft: 0 }}
            onClick={() => {
              setShowDetails(!showDetails);
            }}
          >
            <h3>User Info ⬇️</h3>
            <p>Click to show/hide</p>
          </div>
          <code style={{ display: showDetails ? "block" : "none" }}>
            <pre>{JSON.stringify(userData, null, 2)}</pre>
          </code>
          {showDetails ? (
            <>
              {!addressFormIsOpen ? (
                <>
                  {" "}
                  <h2>Addresses</h2>
                  {userData.addresses?.map((address) => (
                    <>
                      <br></br>
                      <p>
                        <b>Street:</b> {address.street}
                      </p>
                      <p>
                        <b>Secondary:</b> {address.secondary}
                      </p>
                      <p>
                        <b>City:</b> {address.city}
                      </p>
                      <p>
                        <b>State:</b> {address.state}
                      </p>
                      <p>
                        <b>Zip Code:</b> {address.postalCode}
                      </p>
                      <hr></hr>
                    </>
                  ))}
                  <button onClick={() => setAddressFormIsOpen(true)}>Add new address</button>
                </>
              ) : (
                <div style={{ maxWidth: "800px" }}>
                  <AddressForm
                    closeForm={() => setAddressFormIsOpen(false)}
                    onSubmit={createAddress}
                    enableCardDecor
                  />
                </div>
              )}
              <hr></hr>
              <h2>Cases</h2>
              <CaseResults cases={cases} />
            </>
          ) : null}
        </div>
      )}
    </section>
  );
};

export default UserSearch;
