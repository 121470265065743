import { lazy, Suspense, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import useDataLayer, { GA4Events } from "@hooks/useDataLayer";
import { Case } from "@store/../@types/case";
import {
  Condition,
  ConditionTypes,
  conditionTypesIndex,
  UrlConditionTypes,
} from "@store/../@types/condition";
import { RootEntity, RootState } from "@store/../@types/state";
import { SET_CONDITIONS } from "@store/cases/casesActions";
import { removeConditions, setConditions } from "@store/cases/casesSlice";
import { REQUEST_CASE_PRICE, REQUEST_SHIPPING_PRICE } from "@store/prices/pricesActions";
import { OVERNIGHT_SHIPPING_ID, PRIORITY_SHIPPING_ID, VISIT_FEE_ID } from "@utils/constantsEnv";

import DashboardConditionsSummary from "./components/ConditionsPicker/components/ChosenConditionsSummary/DashboardConditionsSummary";
import StartConsultationConditionsSummary from "./components/ConditionsPicker/components/ChosenConditionsSummary/StartConsultationConditionsSummary";
import UpsellConditionsSummary from "./components/ConditionsPicker/components/ChosenConditionsSummary/UpsellConditionsSummary";

// Lazy components
const ConditionsPicker = lazy(() => import("@components/Cart/components/ConditionsPicker"));
const Upsell = lazy(() => import("@components/Upsell"));

export enum PresentationType {
  DASHBOARD = "dashboard",
  START_CONSULTATION_PAGE = "start_consultation_page",
  UPSELL = "upsell",
}

interface CartProps {
  items: string[];
  country: string;
  includesTyphoid?: boolean;
  presentationType?: PresentationType;
}

const Cart = ({
  items,
  country,
  includesTyphoid,
  presentationType = PresentationType.START_CONSULTATION_PAGE,
}: CartProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const newCase = useSelector<RootState, RootEntity<Case>>((state) => state.case.newCase);
  const consultations = newCase.data.conditions.map((condition) => condition.product.name);
  const consultation = conditionTypesIndex.get(
    history.location.pathname.split("/")[1] as UrlConditionTypes
  );

  // Handle Analytic Events
  const { reportAddToCart } = useDataLayer(GA4Events.ADD_TO_CART);
  const { reportRemoveFromCart } = useDataLayer(GA4Events.REMOVE_FROM_CART);

  // Reference for previous conditions - to track additions or removals of the analyitcs events above
  const prevConditionsRef = useRef<Condition[]>(newCase.data.conditions);

  // Load cart elements.
  useEffect(() => {
    if (newCase.data.conditions.length === 0) {
      if (consultation !== ConditionTypes.RUNWAY_PLUS) {
        dispatch({
          type: SET_CONDITIONS,
          consultation,
        });
      }

      if (items.length === 0) {
        dispatch(setConditions({ conditions: [] }));
      } else {
        dispatch({
          type: SET_CONDITIONS,
          pricesIds: items,
        });
      }
    }
  }, []);

  useEffect(() => {
    // Set country state if any is selected.
    if (country) sessionStorage.setItem("country", country);
  }, []);

  // Get services prices.
  useEffect(() => {
    dispatch({
      type: REQUEST_CASE_PRICE,
      priceId: VISIT_FEE_ID,
    });
    dispatch({
      type: REQUEST_SHIPPING_PRICE,
      shippingRatesIds: [PRIORITY_SHIPPING_ID, OVERNIGHT_SHIPPING_ID],
    });
  }, []);

  // useEffect to track additions or removals for analytics events
  useEffect(() => {
    // Capture the previous and current conditions
    const prevConditions = prevConditionsRef.current;
    const currentConditions = newCase.data.conditions;

    // Add to Cart event
    if (currentConditions.length > prevConditions.length) {
      const addedConditions = currentConditions.filter(
        (condition) => !prevConditions.includes(condition)
      );

      addedConditions.forEach((condition) => console.log(condition.product.name));
      if (addedConditions.length > 0) {
        reportAddToCart(addedConditions[0]);
      }
    }

    // Remove from cart event
    if (currentConditions.length < prevConditions.length) {
      const removedConditions = prevConditions.filter(
        (condition) => !currentConditions.includes(condition)
      );

      removedConditions.forEach((condition) => console.log(condition.product.name));
      if (removedConditions.length > 0) {
        reportRemoveFromCart(removedConditions[0]);
      }
    }

    prevConditionsRef.current = currentConditions;
  }, [newCase.data.conditions]);

  const removeCondition = (condition: string) => {
    dispatch(removeConditions(condition));
  };

  return (
    <>
      {presentationType === PresentationType.DASHBOARD && (
        <DashboardConditionsSummary
          conditions={newCase.data.conditions}
          removeCondition={removeCondition}
          includesTyphoid={includesTyphoid ?? false}
        />
      )}
      {presentationType === PresentationType.START_CONSULTATION_PAGE && (
        <StartConsultationConditionsSummary
          consultations={consultations}
          isRunwayPlus={newCase.data.conditions.length === 0}
          removeCondition={removeCondition}
        />
      )}
      {presentationType === PresentationType.UPSELL && newCase.data.conditions.length > 0 && (
        <UpsellConditionsSummary
          conditions={newCase.data.conditions}
          removeCondition={removeCondition}
        />
      )}
      <Suspense fallback={<>...</>}>
        {presentationType === PresentationType.UPSELL ? (
          <Upsell />
        ) : (
          <ConditionsPicker presentationType={presentationType} />
        )}
      </Suspense>
    </>
  );
};

export default Cart;
