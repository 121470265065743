import { makeStyles } from "@material-ui/core/styles";
import { border, color, spacing } from "@runwayhealth/runway-components-react";

const useModalStyle = () => {
  const useStyles = makeStyles((screenWidth: number) => ({
    modal: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    paper: {
      backgroundColor: `${color.neutral.lightest}`,
      borderRadius: screenWidth < 768 ? `${border.radius.lg}` : `${border.radius.xl}`,
      height: "auto",
      margin: `${spacing.nano}`,
      width: screenWidth < 768 ? "100%" : screenWidth < 992 ? "82%" : "74%",
      maxWidth: "1200px",
      position: "relative",
      maxHeight: "100%",
      overflowY: "scroll",
      padding: "45px",
    },
  }));
  return { useStyles };
};

export default useModalStyle;
